<template lang="pug">
	.add-bot-to-resources
		.mb-4.mb-lg-0
			h3.mb-4.mb-xs-3.text-bold {{ $t('instructionBot.step7.title') }}
			p.b3.mb-4.text-grey(v-html="$t('instructionBot.step7.desc')")

		created-bot(:name="'My bot'" :image="''" :login="'@my_bot'")

		.d-flex.justify-content-between.flex-wrap
			a.d-block.text-decoration-none.mb-10.add-bot-to-resources__button(:href="linkToTutorial" target="_blank")
				pw-btn.h-100(:text="`📋 ${$t('instructionBot.instructions.forChannel')}`" :variant="'grey'" :icon="'is-instruction'")
			a.d-block.text-decoration-none.mb-10.add-bot-to-resources__button(:href="linkToTutorial" target="_blank")
				pw-btn.h-100(:text="`📋 ${$t('instructionBot.instructions.forGroup')}`" :variant="'grey'" :icon="'is-instruction'")

		resources-bot-permissions.mb-10(v-for="(card, i) in resources" :key="`resources-${i}`" :card="card")

</template>

<script>
import CreatedBot from '@/components/Cards/CreatedBot';
import ResourcesBotPermissions from '@/components/Cards/ResourcesBotPermissions';
import ResourcesCard from '@/components/Cards/ResourcesCard';
import PwBtn from '@/components/Buttons/PwBtn';
import { mapGetters } from 'vuex';

export default {
    name: 'AddBotToResources',
    components: {
        CreatedBot,
        ResourcesBotPermissions,
        ResourcesCard,
        PwBtn,
    },
    data: () => ({
        selectedStatus: 1,
    }),
    computed: {
        ...mapGetters({
            project: 'project/opened',
        }),
        resources() {
            if (!this.project) return [];

            if (this.selectedStatus == 1) return [...this.project.channels, ...this.project.groups];

            return [];
        },
        linkToTutorial() {
            if (!this.me) return '/';

            return this.me.isPartner
                ? 'https://docs.paywall.pw/rus/obshee/partnerskaya-programma'
                : this.$t('contacts.knowledge_base');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.add-bot-to-resources {
    margin-top: -10px;

    @media (max-width: 991.98px) {
        margin-top: 2px;
    }

    .created-bot {
        margin-bottom: 10px;

        @include respond-below(xs) {
            margin-bottom: 20px;
        }
    }

    &__button {
        width: calc(50% - 5px);

        @include respond-below(xs) {
            width: 100%;
        }

        &:last-of-type {
            @include respond-below(xs) {
                margin-bottom: 17px;
            }
        }
    }
}
</style>
