<template lang="pug">
	.basic-box-shadow.border-rounded.foreground-color.p-4.resources-bot-permissions(v-if="card")
		.d-flex.align-items-center.position-relative
			.d-flex.justify-content-center.align-items-center.resources-card__avatars(:class="{'brand-gradient': !card.image}")
				img.rounded-circle.mr-3(v-if="card.image" :src="card.image.contentUrl" width="53" height="53")
				span(v-else) {{ `${card.title}`.slice(0,1) }}
			.d-flex.flex-column.w-100
				span.b1.text-bold {{ card.title }}
				span.b3.text-lowercase.resources-bot-permissions__sign {{ card.isGroup() ? $t('resources.closedGroup') : $t('resources.closedChannel') }}

			.d-none.d-xs-block.resources-bot-permissions__status(:class="{'is-dark': isDarkColor}")

		.b3.border-rounded.mt-10.mb-10.position-relative.resources-bot-permissions__warning {{ $t('instructionBot.step7.warning') }}

		permissions-bot(v-if="card.isGroup()" :resource="'group'")
		permissions-bot(v-else :resource="'channel'")

</template>

<script>
import PermissionsBot from '@/components/Common/PermissionsBot';

export default {
    name: 'ResourcesBotPermissions',
    components: {
        PermissionsBot,
    },
    props: {
        card: {
            default: () => {},
            type: Object,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.resources-bot-permissions {
    border: 1px solid var(--grayscale-dark-line);
    padding-bottom: 10px !important;

    @include respond-below(xs) {
        padding: 18px 20px 10px 20px !important;
        margin-bottom: 20px;
    }

    &.is-success {
        padding-bottom: 20px !important;

        .resources-bot-permissions {
            &__status {
                display: block !important;

                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' fill='%2359CB94' stroke='%2359CB94' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.75 11.9999L10.58 14.8299L16.25 9.16992' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                }

                &.is-dark {
                    &:before {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' fill='%2359CB94' stroke='%2359CB94' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.75 11.9999L10.58 14.8299L16.25 9.16992' stroke='%2327282A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    }
                }

                @include respond-below(xs) {
                    top: -23px;
                    right: -26px;
                }
            }

            &__warning {
                display: none;
            }
        }
    }

    &.is-error {
        .resources-bot-permissions {
            &__status {
                display: block !important;

                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' fill='%23FF7C7C' stroke='%23FF7C7C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.16992 14.8299L14.8299 9.16992' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.8299 14.8299L9.16992 9.16992' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }

                &.is-dark {
                    &:before {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' fill='%23FF7C7C' stroke='%23FF7C7C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.16992 14.8299L14.8299 9.16992' stroke='%2327282A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.8299 14.8299L9.16992 9.16992' stroke='%2327282A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    }
                }

                @include respond-below(xs) {
                    top: -23px;
                    right: -26px;
                }
            }

            &__warning {
                background-color: var(--red-light-bg-color);
                color: var(--red-text-color);

                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' width='20' height='20' rx='5' stroke='%23FF7C7C' stroke-width='1.5'/%3E%3Cpath d='M12.5 17L12.5 11' stroke='%23FF7C7C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.5 11L12.5 11' stroke='%23FF7C7C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5 8L12.5 7' stroke='%23FF7C7C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    &:last-of-type {
        margin-bottom: 4px !important;
    }

    &__sign {
        margin-top: 5px;
        margin-bottom: 2px;
        color: var(--grey-text-color);
    }

    &__status {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: center / contain no-repeat
                url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' fill='%23FCB64D' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8V13' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.9946 16H12.0036' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }

        &.is-dark {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' fill='%23FCB64D' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8V13' stroke='%2327282A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.9946 16H12.0036' stroke='%2327282A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__warning {
        background-color: var(--brand-bg-warning);
        color: var(--brand-color-warning);
        padding: 22px 20px 25px 54px;

        @include respond-below(xs) {
            padding: 18px 20px 15px 50px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 22px;
            margin: auto;
            left: 19px;
            width: 22px;
            height: 22px;
            background: center / contain no-repeat
                url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='20' height='20' rx='5' stroke='%23FCB64D' stroke-width='1.5'/%3E%3Cpath d='M11.5 16L11.5 10' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.5 10L11.5 10' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 7L11.5 6' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

            @include respond-below(xs) {
                bottom: auto;
                top: 18px;
                left: 15px;
            }
        }

        &.is-dark {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='20' height='20' rx='5' stroke='%23FCB64D' stroke-width='1.5'/%3E%3Cpath d='M11.5 16L11.5 10' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.5 10L11.5 10' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 7L11.5 6' stroke='%23FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
        }
    }
}
</style>
